<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :defaultQueryParam="defaultQueryParams"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCumsMetaPage"
      :deleteFun="deleteCumsMetaInfo"
      :createFun="createCumsMetaInfo"
      :updateFun="updateCumsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getCumsMetaPage, createCumsMetaInfo, updateCumsMetaInfo, deleteCumsMetaInfo } from '@/api/cums'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '付款对象', name: 'name', fieldType: '', queryType: '%'
        },
        {
          label: '英文名称', name: 'eng_name', fieldType: '', queryType: '%'
        }
      ],
      defaultQueryParams: {
        type: 9
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '付款对象',
          dataIndex: 'name'
        },
        {
          title: '英文名称',
          dataIndex: 'eng_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入付款对象', trigger: 'blur' }
        ]
      },
      modelTitle: '付款对象',
      formFields: [
        {
          label: '付款对象', name: 'name', type: 'default'
        },
        {
          label: '英文名称', name: 'eng_name', type: 'default'
        },
        {
          label: '', name: 'type', type: 'hidden', defaultValue: 9
        }
      ],
      moduleName: 'cums_meta_info'
    }
  },
  methods: {
    getCumsMetaPage,
    createCumsMetaInfo,
    updateCumsMetaInfo,
    deleteCumsMetaInfo
  }
}
</script>
